<script lang="ts" setup>
  import type * as CSS from 'csstype'

  const props = withDefaults(
    defineProps<{
      size?: 'sm' | 'md' | 'lg' | 'xl'
      color?: CSS.Property.Color
    }>(),
    {
      size: 'md',
      color: 'var(--color-base-paper)'
    }
  )

  const iconSize = computed(() => {
    switch (props.size) {
      case 'sm':
        return '24px'
      case 'md':
        return '48px'
      case 'lg':
        return '96px'
      case 'xl':
        return '160px'
      default:
        return '48px'
    }
  })
</script>

<template>
  <icon-components-loading-spinner
    :height="iconSize"
    :width="iconSize"
    aria-label="loading spinner"
    class="c-spinner"
  />
</template>

<style lang="scss" scoped>
  .c-spinner {
    animation: animation-rotate 4s linear infinite;
    color: v-bind('color');
    fill: v-bind('color');
  }

  @keyframes animation-rotate {
    100% {
      transform: rotate(360deg);
    }
  }
</style>
